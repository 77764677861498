<template>
    <page title="设置" :hasBack="true" textAlign="left">
        <div class="setting-wrapper">
            <div class="setting-item has-line">
                <div class="label">设置头像</div>
                <div class="right">
                    <img class="avatar" :src="userMsg.avatar" alt="" />
                </div>
            </div>
            <div class="setting-item has-line">
                <div class="label">设置头像</div>
                <div class="right">
                    <span>{{ userMsg.phone }}</span>
                    <van-icon class="icon" name="arrow" />
                </div>
            </div>
            <div class="setting-item">
                <div class="label">修改密码</div>
                <div class="right">
                    <van-icon class="icon" name="arrow" />
                </div>
            </div>
        </div>
        <van-button class="logout-btn" round type="primary">退出登录</van-button>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import { reactive } from 'vue';
import { Icon, Button } from 'vant';
export default {
    name: 'Setting',
    components: {
        [Page.name]: Page,
        [Icon.name]: Icon,
        [Button.name]: Button,
    },
    setup() {
        const userMsg = reactive({
            avatar: require('@/assets/images/user/default_avatar.png'),
            phone: '18612569875',
        });
        return {
            userMsg,
        };
    },
};
</script>
<style lang="less" scoped>
.setting-wrapper {
    background: #ffffff;
    border-radius: 5px;
    .setting-item {
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;
        .avatar {
            width: 26px;
            border-radius: 50%;
        }
    }
    .has-line {
        &::after {
            content: '';
            left: 34px;
            right: 0;
            height: 1px;
            position: absolute;
            bottom: 0;
            background: #f2f2f2;
        }
    }
    .right {
        span {
            font-size: 12px;
            font-family: PingFang SC, serif;
            color: #333333;
        }
        .icon {
            color: #333333;
            margin-left: 5px;
        }
    }
}
.logout-btn {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    box-shadow: none;
    margin-top: 50px;
    font-size: 18px;
    height: 49px;
    font-family: PingFang SC, serif;
    color: #333333;
}
</style>
